import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EyeIcon from 'assets/EyeIcon';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import TagsContainer from '../../../../../../../components/TagButtonContainer';
import Tooltip from '../../../../../../../components/Tooltip';
import utilities from '../../../../../../../utilities';
import routesEnum from '../../../../../../../utilities/enums/routesEnum';
import setClassSuffix from '../../../../../../../utilities/services/ClassManager';
import { CaseContext, MatchesFilterContext } from '../../../../../../../utilities/services/contexts';
import { AML_FILTERS } from '../../../../enums';
import HitStatusRowControls from '../../../HitStatusRowControls';

const MatchesTableRow = ({
  rowInfo: {
    id,
    checked,
    name,
    entityType,
    relevance,
    commentsCount,
    countries,
    matched,
    resultsFound,
    updatedByOM,
    status,
    deprecated,
  },
  className,
  onCheckboxClick,
  onControlsClick,
}) => {
  const { complianceLogId: caseId, isCaseClosed } = useContext(CaseContext);
  const { selectedAMLFilters } = useContext(MatchesFilterContext);

  const history = useHistory();

  const setColumnSuffix = setClassSuffix(`${className}__column`);

  const handleCheckBoxClick = useCallback(() => {
    onCheckboxClick(id);
  }, [id, onCheckboxClick]);

  const handleChange = useCallback(
    value => {
      onControlsClick(id, value);
    },
    [id, onControlsClick],
  );

  const navigateToListings = useMemo(() => {
    return utilities.routeParams(routesEnum.kyc.CASE_MATCH_DETAILS, {
      complianceLogId: caseId,
      hitId: id,
    });
  }, [id, caseId]);

  const mappedMatches = useMemo(() => matched.map(match => ({ name: AML_FILTERS[match] })), [matched]);

  const handleClick = useCallback(() => {
    !deprecated && history.push(navigateToListings);
  }, [deprecated, navigateToListings, history]);

  const classes = classNames(`${className}__row`, {
    [`${className}__row--disabled`]: deprecated,
  });
  return (
    <tr key={id} className={classes} onClick={handleClick}>
      <td className={setColumnSuffix('__checkbox')}>
        <input
          data-lpignore="true"
          type="checkbox"
          checked={checked}
          disabled={isCaseClosed}
          readOnly
          onChange={handleCheckBoxClick}
          onClick={e => e.stopPropagation()}
        />
      </td>
      <td className={setColumnSuffix('__ongoingAlert')}>
        {updatedByOM && status === 'potential' && (
          <Tooltip
            placement="top"
            content={<span>New Ongoing Monitoring Alert</span>}
            trigger={
              <span>
                <EyeIcon />
              </span>
            }
          />
        )}
      </td>
      <td className={setColumnSuffix('__name')}>{name}</td>
      <td className={setColumnSuffix('__type')}>{entityType}</td>

      <td className={setColumnSuffix('__relevance')}>{relevance}</td>

      <td className={setColumnSuffix('__countries')}>{countries}</td>

      <td className={setColumnSuffix('__riskTypeCount')}>
        <TagsContainer
          options={mappedMatches}
          red
          outline
          preview
          filtersToHighlite={selectedAMLFilters.map(val => AML_FILTERS[val] || '')}
        />
      </td>

      <td className={setColumnSuffix('__resultsFound')}>{deprecated ? '-' : resultsFound}</td>
      <td className={setColumnSuffix('__comments')}>
        {commentsCount > 0 && (
          <>
            <h3>{commentsCount}</h3>
            <ChatBubbleIcon />
          </>
        )}
      </td>
      <td className={setColumnSuffix('__controls')}>
        {!deprecated && <HitStatusRowControls onClick={handleChange} isControlActive={!isCaseClosed} />}
      </td>
    </tr>
  );
};

MatchesTableRow.propTypes = {
  rowInfo: PropTypes.shape({
    value: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    checked: PropTypes.bool,
    alpha2Code: PropTypes.string,
    name: PropTypes.string,
    text: PropTypes.string,
    entityType: PropTypes.string,
    relevance: PropTypes.string,
    countries: PropTypes.string,
    matched: PropTypes.string,
    messageNum: PropTypes.string,
    commentsCount: PropTypes.number,
    deprecated: PropTypes.bool,
  }),
  className: PropTypes.string.isRequired,
  onCheckboxClick: PropTypes.func,
  onControlsClick: PropTypes.func,
};

MatchesTableRow.defaultProps = {
  rowInfo: {
    alpha2Code: '',
    name: '',
    value: '',
    text: '',
    checked: false,
    commentsCount: 0,
    deprecated: false,
  },
  onCheckboxClick: () => {},
  onControlsClick: () => {},
};

export default React.memo(MatchesTableRow);
