import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import TabHeader from '../../../../../components/Headers/TabHeader';
import enums from '../../../../../utilities/enums';
import { CASE_MATCH_STATUS } from '../../enums';
import AdverseMediaListings from './components/AdverseMediaListings';
import DocumentsListings from './components/DocumentsListings';
import FullListings from './components/FullListings';
import './styles.scss';

const Listings = ({ selectedAMLFilters, complianceLogId, hitId, hitCounts, updateHitCounts }) => {
  const [selectedStatus, setSelectedStatus] = useState(CASE_MATCH_STATUS.POTENTIAL);
  const [openAccordionIndex, setOpenAccordionIndex] = useState(-1);
  const buttonProps = [
    {
      text: 'Potential ',
      value: CASE_MATCH_STATUS.POTENTIAL,
      badgeValue: hitCounts.potentialCounter || 0,
      onClick: () => {
        setSelectedStatus(CASE_MATCH_STATUS.POTENTIAL);
      },
      selected: selectedStatus === CASE_MATCH_STATUS.POTENTIAL,
      isRed: true,
    },
    {
      text: 'Unknown ',
      badgeValue: hitCounts.unknownCounter || 0,
      value: CASE_MATCH_STATUS.UNKNOWN,
      onClick: () => {
        setSelectedStatus(CASE_MATCH_STATUS.UNKNOWN);
      },
      selected: selectedStatus === CASE_MATCH_STATUS.UNKNOWN,
    },
    {
      text: 'Rejected ',
      badgeValue: hitCounts.rejectedCounter || 0,
      value: CASE_MATCH_STATUS.REJECTED,
      onClick: () => {
        setSelectedStatus(CASE_MATCH_STATUS.REJECTED);
      },
      selected: selectedStatus === CASE_MATCH_STATUS.REJECTED,
    },
    {
      text: 'Accepted ',
      badgeValue: hitCounts.acceptedCounter || 0,
      value: CASE_MATCH_STATUS.ACCEPTED,
      onClick: () => {
        setSelectedStatus(CASE_MATCH_STATUS.ACCEPTED);
      },
      selected: selectedStatus === CASE_MATCH_STATUS.ACCEPTED,
    },
  ];

  useEffect(() => {
    const { listingsCounter, adverseMediaCounter, documentsCounter } = hitCounts;
    setOpenAccordionIndex(() => {
      if (listingsCounter > 0) {
        return enums.ACCORDION_INDEXES.FULL_LISTINGS;
      }
      if (adverseMediaCounter > 0) {
        return enums.ACCORDION_INDEXES.ADVERSE_LISTINGS;
      }
      if (documentsCounter > 0) {
        return enums.ACCORDION_INDEXES.IDENTITY_DOCUMENTS;
      }
    });
  }, [hitCounts]);
  return (
    <div className="ickyc-listings">
      <TabHeader title="Listings" buttonProps={buttonProps} />
      <FullListings
        complianceLogId={complianceLogId}
        hitId={hitId}
        selectedStatus={selectedStatus}
        selectedAMLFilters={selectedAMLFilters}
        onHitStatusUpdate={updateHitCounts}
        count={hitCounts.listingsCounter}
        shouldOpen={openAccordionIndex === enums.ACCORDION_INDEXES.FULL_LISTINGS}
      />
      <AdverseMediaListings
        complianceLogId={complianceLogId}
        hitId={hitId}
        selectedStatus={selectedStatus}
        onHitStatusUpdate={updateHitCounts}
        count={hitCounts.adverseMediaCounter}
        shouldOpen={openAccordionIndex === enums.ACCORDION_INDEXES.ADVERSE_LISTINGS}
      />
      <DocumentsListings
        complianceLogId={complianceLogId}
        hitId={hitId}
        selectedStatus={selectedStatus}
        count={hitCounts.documentsCounter}
        shouldOpen={openAccordionIndex === enums.ACCORDION_INDEXES.DOCUMENTS_LISTINGS}
        onHitStatusUpdate={updateHitCounts}
      />
    </div>
  );
};
Listings.propTypes = {
  selectedAMLFilters: PropTypes.arrayOf(PropTypes.number),
  complianceLogId: PropTypes.string.isRequired,
  hitId: PropTypes.string.isRequired,
  hitCounts: PropTypes.shape({
    acceptedCounter: PropTypes.number,
    adverseMediaCounter: PropTypes.number,
    documentsCounter: PropTypes.number,
    listingsCounter: PropTypes.number,
    potentialCounter: PropTypes.number,
    rejectedCounter: PropTypes.number,
    unknownCounter: PropTypes.number,
  }),
  updateHitCounts: PropTypes.func,
};
Listings.defaultProps = {
  selectedAMLFilters: [],
  hitCounts: {},
  updateHitCounts: () => {},
};
export default Listings;
