import bus from 'modules/bus';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import api from '../../../../../../../api';
import Accordion from '../../../../../../../components/Accordion';
import CheckboxTable from '../../../../../../../components/Tables/CheckboxTable';
import TableHeader from '../../../../../../../components/Tables/Table/components/TableHeader';
import enums from '../../../../../../../utilities/enums';
import { CaseContext } from '../../../../../../../utilities/services/contexts';
import MatchesControls from '../../../Matches/components/MatchesControls';
import DocumentListingRow from './components/DocumentListingRow';
import './styles.scss';

const DocumenstListings = ({ complianceLogId, hitId, selectedStatus, shouldOpen, count, onHitStatusUpdate }) => {
  const [params, setParams] = useState({ sortOrder: 0 });
  const [pagination, setPagination] = useState({});

  const [isloading, setIsLoading] = useState(false);

  const [documents, setDocuments] = useState([]);

  const { isCaseClosed } = useContext(CaseContext);

  const changeParams = useCallback(newParams => {
    setParams(prev => ({ ...prev, ...newParams }));
  }, []);

  useEffect(() => {
    const fetchHitListings = async () => {
      setIsLoading(true);
      try {
        const {
          data: { documents, counts },
        } = await api.kyc.caseManagement.getHitDocuments({
          hitId,
          complianceLogId,
          params: { status: selectedStatus, ...params },
        });
        onHitStatusUpdate(prev => ({
          ...prev,
          documentsCount: counts?.documentsCount,
        }));
        setDocuments(documents.data);
        setPagination(documents.paginationData);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };
    fetchHitListings();
  }, [complianceLogId, hitId, params, selectedStatus]);

  const updateSelectedHitsStatus = async (newStatus, selectedDocuments) => {
    setIsLoading(true);
    try {
      const response = await api.kyc.caseManagement.updateHitStatus({
        complianceLogId,
        hitId,
        status: newStatus,
        ids: selectedDocuments,
      });
      setDocuments(prev => prev.filter(match => !selectedDocuments.some(m => m === match.id)));
      onHitStatusUpdate(prev => ({
        ...prev,
        [`${selectedStatus}Counter`]: prev[`${selectedStatus}Counter`] - selectedDocuments.length,
        [`${newStatus}Counter`]: prev[`${newStatus}Counter`] + selectedDocuments.length,
      }));

      bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, response.data);
      setParams(prev => {
        const limitDiff = prev.limit - selectedDocuments.length;
        return {
          total: prev.total - selectedDocuments.length,
          limit: !limitDiff ? prev.limit : limitDiff,
        };
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Accordion
      title={<h3>Documents</h3>}
      accented
      badgeCount={count}
      className="ickyc-hit-documents-accordion"
      accordionindex={enums.ACCORDION_INDEXES.DOCUMENTS_LISTINGS}
      accordionOpen={shouldOpen}
      withBadge
    >
      <CheckboxTable
        disabled={isCaseClosed}
        values={documents}
        pagination={pagination}
        tableRow={DocumentListingRow}
        withPagination
        headerRow={TableHeader}
        TableControlsComponent={MatchesControls}
        handleParamsChange={changeParams}
        onControls={updateSelectedHitsStatus}
        updating={isloading}
        headerData={{
          sortCriteria: params.sortCriteria,
          sortOrder: params.sortOrder,
          items: [
            {
              type: 'checkbox',
            },
            {
              type: 'ongoingAlert',
            },
            {
              type: 'icon',
            },
            {
              type: 'source',
              label: 'URL',
            },
          ],
        }}
      />
    </Accordion>
  );
};

DocumenstListings.propTypes = {
  complianceLogId: PropTypes.string.isRequired,
  hitId: PropTypes.string.isRequired,
  selectedStatus: PropTypes.string.isRequired,
  shouldOpen: PropTypes.bool,
  count: PropTypes.number,
};
DocumenstListings.defaultProps = {
  shouldOpen: false,
  count: 0,
};
export default DocumenstListings;
